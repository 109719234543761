/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
// Components
import Layout from "../components/Layout";
import ProductList from "../components/ProductList";


const ProductGroup = ({pageContext}) => {
    const group = pageContext.group;
    return (
        <Layout location={group.path}>
            <div>
                <div className="ctr">
                    <span className="h4 nmgb">Наши решения</span>
                    <h1>{group.name}</h1>
                </div>
                <div className="result ajax_bigger_wrapper" id="ajax_produit" data-wrapper="produit">
                    <div className="quadra">
                        <ProductList group={group}/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ProductGroup
